import { UserManager } from "oidc-client";
import { OidcConfig } from "helpers/configs/OidcConfig";
import LoggerService from "./LoggerService";
import { Location } from "history";

class AuthenticationService {
  private userManager: UserManager;

  constructor() {
    this.userManager = new UserManager(OidcConfig);
  }

  get UserManager(): UserManager {
    return this.userManager;
  }

  getAccessToken(): Promise<string> {
    return this.userManager
      .getUser()
      .then((authResult) => authResult?.access_token || "")
      .catch(() => "");
  }

  async authenticateUser(location: Location<any>) {
    if (!this.userManager || !this.userManager.getUser) {
      return;
    }

    let oidcUser = await this.userManager.getUser();
    let signinTimeStamp;

    function getCookie(name: string) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    }

    function setCookie(cname: string, cvalue: number, expires: string) {
      document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.abplivestock.com";
    }

    if (!oidcUser || oidcUser.expired) {
      LoggerService.debug("authenticating user ...");

      // let url = location.location.pathname + (location.location.search || "");
      let url = location.pathname + (location.search || "");
      let d = new Date();
      d.setTime(d.getTime() + 30 * 60 * 1000);
      signinTimeStamp = d.getTime();

      let expires = "expires=" + d.toUTCString();
      setCookie("stateStamp", signinTimeStamp, expires);

      document.cookie = "signinTimeStamp=" + signinTimeStamp + "; expires=Thu, 01 Jan 2999 00:00:00 UTC; path=/; domain=.abplivestock.com";

      await this.userManager.signinRedirect({
        data: { url },
        extraQueryParams: {
          stateStamp: signinTimeStamp,
        },
      });
    } else {
      const lastLoginName = getCookie("lastLogin");
      if (lastLoginName && lastLoginName !== "S5E-SPA") {
        localStorage.clear();
        window.location.reload();
      }
    }
  }

  async signOut() {
    if (!this.userManager || !this.userManager.getUser) {
      return;
    }

    let oidcUser = await this.userManager.getUser();
    if (oidcUser) {
      LoggerService.info("Logout user...");
      localStorage.clear();
      document.cookie = "stateStamp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.abplivestock.com";
      await this.userManager.signoutRedirect({ id_token_hint: oidcUser.id_token });
    }
  }

  signOutTemporaryUser() {
    localStorage.clear();
    document.cookie = "stateStamp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.abplivestock.com";
    window.location.reload();
  }

  clearTemporaryUser() {
    localStorage.removeItem("temporaryUser");
  }
}

const authenticationServiceInstance = new AuthenticationService();
export default authenticationServiceInstance;
