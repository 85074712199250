import * as yup from "yup";

function isValidArray(message: string, mapper = (a: any) => a) {
  return yup.array().test("requiredArray", message, function (list: any) {
    return list.some((obj: any) => Object.values(obj).some((v) => !!v));
  });
}

yup.addMethod(yup.array, "requiredArray", isValidArray);

export default yup;
